

.container_Rst .form_reset form {
  max-width: 100%;
}

.RP_img{
  position:relative;
  top:0px;
  left:0px;
}
.skills_logo{
  position:absolute;
  top:50px;
  left:50px;
}
.container_Rst {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container_Rst .form_reset {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.container_Rst .form_reset form {
  position: relative;
  /* width: 100%; */
  padding: 0 0 0 0px;
}

.container_Rst .form_reset form h2 {
  color: #262261;
  opacity: 1;
  font-size: 45px;
  font-weight: 800;
  margin-bottom: 15%;
  font-family: "Cocogoose";
    // font-weight: bold; 
}

.container_Rst .form_reset form .input_RsT {
  position: relative;
  margin-bottom: 40px;
  margin-right: -16px;
}


.container_Rst .form_reset form .input_RsT:last-child {
  margin-bottom: 0px;
}

.container_Rst .form_reset form .input_RsT input {
  position: relative;
  background: transparent;
  outline: none;
  border: none;
  color: #262261;
  opacity: 0.7;
  padding-bottom: 10px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 300;
  border-bottom: 2px solid #ecbd2e;
  resize: none;
  font-family: "Comfortaa";
    font-weight: 200; 
}

.container_Rst .form_reset form .input_RsT input[type="submit"] {
  padding: 8px 20px;
  margin-top: -10px;
  width: 530px;
  cursor: pointer;
  background: #f05a28;
  opacity: 1;
  color: #fff;
  font-size: 18px;
  font-weight: 400px;
  border: none;
  border-radius: 5px;
  font-family: "Comfortaa";
    font-weight: 200; 
}

.container_Rst .form_reset form .input_RsT span {
  position: absolute;
  left: 0;
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  pointer-events: none;
  letter-spacing: 1px;
  color: #262261;
  opacity: 0.9;
  transition: all 0.2s;
  font-family: "Comfortaa";
    font-weight: 200; 
}

.container_Rst .form_reset form .input_RsT input:focus~span,
.container_Rst .form_reset form .input_RsT input:valid~span {
  transform: translateY(-24px);
  color: #262261;
  opacity: 0.7;
  font-size: 14px;
}

.container_Rst .form_reset form .input_RsT input:focus,
.container_Rst .form_reset form .input_RsT input:valid {
  border-bottom-color: #ecbd2e;
}

.container_Rst .img_Rs {
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.container_Rst .img_Rs img {
  position: absolute;
  max-height: 100%;
}

img.RP_img {
  width: 100%;
}

.rst_input {
  width: 530px;
  margin-top: 1%;
}

.h2_Rsec {
  text-align: center;
  padding-right: 0px;
}

.icon_eye {
  margin-left: -20px;
  color: #262261;
}

.cross_eye_icon {
  margin-left: -3%;
}

.desk_RstPs {
  display: none;
}

.cross_eye_icon_reset{
  margin-left: -50%;
  color: black;
}

.password_toggle_btn_reset {
  border: none;
  background-color: white;
  margin-left: -12px;
  position: absolute;
}

.authentication{
    overflow-x: hidden;
}

.authentication-box {
    height: 70vh;
    margin: 90px auto 0;
}

.password_toggle_btn {
    background: none;
    border: none;
    color: #000;
    margin-left: -30px;
    position: relative;
}

.cross_eye_icon_log {
    color: #000;
    margin-left: -50%;
}

.sign-up-btn{
    background-color: #32bec0;
    border-color: #32bec0;
    text-transform: uppercase;
    transition: all .5s;
    border: none;
}

.authentication-content {
    background-image: url(../../assets/images/banner_bg.png);
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: right;
    display: flex;
    padding: 0 80px;
    position: relative;
  }
  
  .authentication-content h1 {
    font-size: 48px;
    color: #fff;
    font-weight: 700;
  }
  
  .authentication-content h1 span {
    color: #084b83;
    display: flex;
  }
  
  .authentication-content p {
    color: #fff;
  }
  
  .authentication-content img {
    width: 230px;
    position: absolute;
    top: 80px;
  }
  
  
  .btn-secondary {
      background-color: #32bec0;
      border-color: #32bec0;
      text-transform: uppercase;
      transition: all .5s;
  }


/* Ipad Media Queries */
@media screen and (min-device-width: 820px) and (max-device-width: 1180px) {
  .container_Rst .form_reset form {
    padding: 0 0 0 0px;
    /* margin-left: 50px; */
  }

  .h2_Rsec {
    text-align: center !important;
    margin-left: 2% !important;
  }

  .rst_input {
    width: 269px !important;
  }

  .container_Rst .form_reset form .input_RsT input[type="submit"] {
    width: 269px !important;
  }

  .container_Rst .form_reset form {
    width: 270px;
  }

  img.RP_img {
    width: 100%;
    height: 657px;
  }

  .container_Rst .form_reset form h2 {
    margin-bottom: 10px !important;
    display: contents !important;
    font-size: 33px !important;
  }

  .container_Rst .form_reset form .input_RsT {
    margin-bottom: 40px;
    margin-top: 12%;
  }

  .h2_Rsec {
    padding-right: 10px;
  }

  .container_Rst .form_reset {
    margin-bottom: 50% !important;
    margin-top: 10% !important;
  }

  .cross_eye_icon {
    margin-left: -6% !important;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .rst_input {
    width: 270px;
  }

  .container_Rst .form_reset form .input_RsT input[type="submit"] {
    width: 270px;
  }

  .h2_Rsec {
    padding-left: 5% !important;
  }

  img.RP_img {
    height: 800px;
  }

  .container_Rst .form_reset form h2 {
    font-weight: 800;
    display: contents;
    font-size: 34px;
  }

  .container_Rst .form_reset form .input_RsT {
    margin-bottom: 40px;
    margin-top: 12%;
  }

  .container_Rst .form_reset {
    margin-bottom: 30%;
    margin-top: 10%;
  }

  .cross_eye_icon {
    margin-left: -6%;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 480px) {

  // .img_Rs{
  //   display: none;
  // }
  .container_Rst {
    height: 100%;
  }

  .desk_RstPs {
    display: block;
    // top: -80px;
    // left: 38px;
    // position: absolute;
    text-align: center;
    margin: 30px 0px;
  }

  .desk_RstPs_img {
    width: 75%;
  }

  .container_Rst .form_reset {
    width: 100%;
    padding: 0 45px;
  }

  .container_Rst .form_reset form {
    width: 100%;
    // max-width: 500px;
    padding: 0;
  }

  .container_Rst .img_Rs {
    display: none;
  }

  .rst_input {
    width: 97%;
  }

  .container_Rst .form_reset form .input_RsT input[type=submit] {
    width: 97%;
  }

  .head_sec {
    // text-align: left;
  }

  .container_Rst .form_reset form h2 {
    font-size: 26px;
    /* margin-left: 34px; */
    text-align: center;
    padding-right: 0px;
    font-weight: 900;
  }
}

@media screen and (min-device-width: 540px) and (max-device-width: 720px) {

  .desk_RstPs {
    display: block;
    top: -25% !important;
    left: 30px;
    position: absolute;
  }

  .desk_RstPs_img {
    width: 100%;
    margin-left: 50%;
  }

  .container_Rst .form_reset {
    width: 100%;
    padding: 0 45px;
  }

  .container_Rst .form_reset form {
    width: 100%;
    max-width: 500px;
    padding: 0;
    margin-top: 40%;
  }

  .container_Rst .img_Rs {
    display: none;
  }

  .container_Rst .form_reset form .input_RsT input[type="submit"] {
    width: 450px;
  }

  .container_Rst .form_reset form h2 {
    font-size: 38px;
    // margin-left: 60px;
    /* display: contents; */
  }

  .rst_input {
    width: 449px;
  }

  .cross_eye_icon {
    margin-left: -4%;
  }
}

@media screen and (min-width: 1024px) and (max-height: 600px) {
  .container_Rst .form_reset {
    margin-bottom: 0% !important;
  }
}

@media screen and (max-width: 1248px) {
  .img_Rs {
    display: none !important;
  }

  .rst_input {
    max-width: 100%;
  }

  .form_reset {
    width: 100% !important;
    // max-width: 580px !important;
  }

  .container_Rst {
    display: block;
  }
}