.custom_filter{
    background-color: transparent !important;
    color: #A3A6B4 !important;
    border: 1px solid #A3A6B4 !important;
    border-radius: 25px !important;
}
.custom_filter:focus{
    outline: none !important;
    box-shadow: none !important;
} 

.menu-list.custom-scroll {
    overflow-y: scroll;
    height: 300px;
    overflow-x: hidden;
}
  
  .menu-list.custom-scroll::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: ffff;
  }
  
  .menu-list.custom-scroll::-webkit-scrollbar {
    width: 5px;
  }
  
  .menu-list.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #CED0D1;
    border: 1px solid #CED0D1;
  }
  .schedule_popup_btn_2 {
    font-size: 14px;
    color: white;
    background-color: #34c38f;
    border: 1px solid #34c38f;
    padding: 8px 5px;
    border-radius: 6px;
    float: left;
    width: 45%;
    margin: 0px;
    font-family: "Comfortaa";
    font-weight: 200;
}
  
.schedule_popup_btn {
    font-size: 14px;
    color: #34c38f !important;
    background-color: white !important;
    border: 1px solid #34c38f;
    padding: 8px 18px;
    border-radius: 6px;
    float: left;
    width: 40%;
    margin: 0px;
    font-family: "Comfortaa";
    font-weight: 200;
}

@media screen and (max-width: 425px) {
    .class-center {
        text-align: center;
    }
    .set-width {
        width: 60%;
    }
    .custom_filter {
        width: 61%;
    }
}

@media screen and (max-width: 320px) {
    .set-width {
        width: 86%;
    }
    .custom_filter {
        width: 88%;
    }
}
