.popup{
    position: fixed;
    top: 80px;
    left: 300px;
    width: 50%;
    height: 70%;
    background: #ffffff;
    z-index: 3;
    border: 3px solid #ccc;
}
.popup-shop{
    position: fixed;
    top: 70px;
    left: 300px;
    width: 50%;
    height: 84%;
    background: #ffffff;
    z-index: 3;
    border: 3px solid #ccc;
}
ul.pagination {
    justify-content: end;
    margin-top: 5px;
    flex-wrap: wrap;
}