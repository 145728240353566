.dropdown_list2 {
    width: 100% !important;
    background: transparent !important;
    color:  #495057 !important;
    border: 1px solid #ced4da !important;
    text-align: left !important;
}
.dropdown_list2:focus{
    border-color: transparent;
outline: none !important;
}
.dropdown_list2::after{
    display: none;
}
.custom-drop-menu{
    min-width: 100% !important;
}
button.css-1r4vtzz {
    width: 100% !important;
}
button.css-48ayfv {
    width: 100% !important;
}
.css-1pcexqc-container {
    width: 100%;
    background-color: white !important;
}