.create-voucher-btn {
    background-color: #34c38f !important;
    color: #fff !important;
    border-color: #34c38f !important;
    margin-top: 28px;
}
.create-btn{
    color: #F9F9F9 !important;
    background-color: #262261 !important;
    border-color: #262261 !important;
    display: flex!important;
    align-items: center;
    margin: auto 0 auto auto;
}
.search-drop-fix{
    width: 100% !important;
}